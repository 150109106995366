import styled from "styled-components";

export const SpinWrapper = styled.div`
  text-align: center;
  color:#FF9900;

  .ant-spin .ant-spin-dot-item{
    background-color: #FF9900 !important;
  }
`;
