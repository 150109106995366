import { createSlice } from '@reduxjs/toolkit';

interface initialStateTypes {
  isAuthorized: boolean;
  loading: boolean;
  hasErrors: boolean;
  data: [];
  pageCount: Number;
  pagination:{};
  sorting:{};
}

export interface finalStateTypes {
  app: {
    isAuthorized: boolean;
    loading: boolean;
    hasErrors: boolean;
    data: [];
    pageCount: Number;
    pagination:{};
    sorting:{};
  };
}

const initialState: initialStateTypes = {
  isAuthorized: false,
  loading: false,
  hasErrors: false,
  data: [],
  pageCount: 0,
  pagination:{},
  sorting:{},
};

const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
    },
    requestCompleted: (state) => {
      state.loading = false;
    },

    requestSuccess(state, { payload }) {
      state.data = payload;
      state.hasErrors = false;
      state.loading = false;
    },

    requestFailure(state) {
      state.loading = false;
      state.hasErrors = true;
    },

    resetData(state) {
      state.data = [];
    },

    resetPageCount(state, { payload }) {

      state.pageCount = payload;
    },

    setPagination(state, { payload }) {
      console.log(payload, 'at redux')
      state.pagination = payload;
    },
    setSorting(state, { payload }) {
      console.log(payload, 'at redux')
      state.sorting = payload;
    },
  },
});

export const data = (state: finalStateTypes) => state.app.data;
export const pagination = (state: finalStateTypes) => state.app.pagination;
export const sorting = (state: finalStateTypes) => state.app.sorting;
export const count = (state: finalStateTypes) => state.app.pageCount;
export const loading = (state: finalStateTypes) => state.app.loading;

export const {
  requestStart,
  requestSuccess,
  requestFailure,
  requestCompleted,
  resetData,
  resetPageCount,
  setPagination,
  setSorting

} = appReducer.actions;

export default appReducer.reducer;
