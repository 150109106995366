import { Spin } from "antd";
import { SpinWrapper } from "./styles";

const LoadingSpinner = () => {
  return (
    <SpinWrapper>
      <Spin />
    </SpinWrapper>
  );
};

export default LoadingSpinner;
