import { Button, Image, Layout } from 'antd';
import { LayoutWrapper } from './styles';
import Logo from './../../../Assets/Logos/logo.svg';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserIcon from './../../../Assets/Icons/user-icon.svg';
import Footer from 'src/Components/Shared/Footer';
import { GridWraper } from 'src/Styles/global';
import { AppRoutes } from 'src/Components/Constant/routes';
import Logout from 'src/Assets/Icons/logout';
import { useDispatch, useSelector } from 'react-redux';
import { LoginAction, QuickbookLogin } from 'src/Redux/App/Actions/Auth';
import Quickbook from 'src/Assets/Icons/Quickbook';
import { setPagination, setSorting } from 'src/Redux/App';

const { Content } = Layout;
const { Header } = Layout;

interface LayoutPropsTypes {
  children: JSX.Element;
}

const CustomLayout: React.FC<LayoutPropsTypes> = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathsWithFixedFooter = [
    AppRoutes.forgot,
    AppRoutes.login,
    AppRoutes.reset,
  ];
  const EmployerLoginButton = [
    AppRoutes.forgot,
    AppRoutes.login,
    AppRoutes.reset,
  ];

  const isFooterFixed = pathsWithFixedFooter.some((path) =>
    location.pathname.startsWith(path)
  );
  const logOut = () => {
    dispatch(setPagination({}));
    dispatch(setSorting({}));
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.setItem('key', '1');
    localStorage.removeItem('quickbookAdmin');
  };
  const onQuickbookLogin = () => {
    dispatch<any>(QuickbookLogin());
  };
  return (
    <LayoutWrapper>
      <Layout>
        <Header>
          <div className='header'>
            <div className='header-position'>
              <Link to={AppRoutes.home}>
                <Image
                  src={Logo}
                  preview={false}
                />
              </Link>
            </div>
            {localStorage.getItem('quickbookAdmin') == 'true' ? (
              <div
                style={{ float: 'right', position: 'relative', left: '60px' }}
              >
                {' '}
                <Button
                  onClick={() => onQuickbookLogin()}
                  className='qickbook-btn'
                  htmlType='button'
                  // loading={loading}
                >
                  <Quickbook /> Fetch Quickbooks Token
                </Button>
              </div>
            ) : (
              ''
            )}
            <div>
              {localStorage.getItem('token') ? (
                <Link
                  to={AppRoutes.login}
                  onClick={() => logOut()}
                  className='btn-cta'
                >
                  <Logout /> Log out
                </Link>
              ) : (
                ''
              )}
            </div>
          </div>
        </Header>
        <Layout className='site-layout'>
          <GridWraper>
            <Content>{children}</Content>
          </GridWraper>
        </Layout>
        {/* Fixed Footer for pages have less content */}
        {isFooterFixed ? <Footer fixed /> : <Footer />}
      </Layout>
    </LayoutWrapper>
  );
};

export default CustomLayout;
